import { create } from "apisauce";

const apiClient = create({
  // baseURL: "https://backend.subhashsuperstore.in/api",
  // baseURL: "http://localhost:5000/api",
  // baseURL: "http://192.168.29.37:5000/api",
 baseURL: "http://43.205.206.119:5000/api",
  headers: { Accept: "application/vnd.github.v3+json" },
});

export default apiClient;
