import React from "react";
import { Link } from "react-router-dom";
const SideNavBar = () => {
  return (
    <div id="layoutSidenav_nav">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link className="nav-link active" to="/">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt" />
              </div>
              Dashboard
            </Link>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseProducts"
              aria-expanded="false"
              aria-controls="collapseProducts"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-box" />
              </div>
              Products
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseProducts"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/product/list">
                  All Products
                </Link>
                <Link className="nav-link sub_nav_link" to="/product/search">
                  Search Products
                </Link>
                <Link className="nav-link sub_nav_link" to="/order-again">
                  Order Again Products
                </Link>

                <Link className="nav-link sub_nav_link" to="/product/create">
                  Add Product
                </Link>

                <Link className="nav-link sub_nav_link" to="/product/excel">
                  Bulk Upload Products & Images
                </Link>
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseCategories"
              aria-expanded="false"
              aria-controls="collapseCategories"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-list" />
              </div>
              Categories
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseCategories"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/category/list">
                  All Categories
                </Link>
                <Link className="nav-link sub_nav_link" to="/category/sublist">
                  All Sub-Categories
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/speciallist"
                >
                  All Special-Categories
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/create-category"
                >
                  Add Category
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/sub-create"
                >
                  Add Sub-Category
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/category/special-category"
                >
                  Add Special-Category
                </Link>
              </nav>
            </div>

            <Link className="nav-link" to="/customer/list">
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Customers
            </Link>
            <Link className="nav-link" to="/reward">
              <div className="sb-nav-link-icon">
                <i className="fas fa-gift" />
              </div>
              Reward
            </Link>

            <Link className="nav-link" to="/list-coupens">
              <div className="sb-nav-link-icon">
                <i className="fas fa-gift" />
              </div>
              Coupens
            </Link>
            <Link className="nav-link" to="/list-pincodes">
              <div className="sb-nav-link-icon">
                <i className="fas fa-map-pin" />
              </div>
              Delivery Pincode
            </Link>
            <Link className="nav-link" to="/list-deliveryslot">
              <div className="sb-nav-link-icon">
                <i className="fas fa-clock" />
              </div>
              Delivery Slot
            </Link>
            <Link className="nav-link" to="/all-theme">
              <div className="sb-nav-link-icon">
                <i class="fas fa-eye-dropper"></i>
              </div>
              App Color
            </Link>
            <Link className="nav-link" to="/all-heading">
              <div className="sb-nav-link-icon">
                <i className="fas fa-font" />
              </div>
              App Heading
            </Link>

            <Link className="nav-link" to="/customer/dealerlist">
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              Dealers
            </Link>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseOrders"
              aria-expanded="false"
              aria-controls="collapseOrders"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-shipping-fast" />
              </div>
              Orders
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseOrders"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/orders/list">
                  Order's
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link
                  className="nav-link sub_nav_link"
                  to="/orders/order-filter"
                >
                  Order Filter
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link
                  className="nav-link sub_nav_link"
                  to="/orders/failed-online"
                >
                  Failed Transactions
                </Link>
              </nav>
            </div>

            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseVarList"
              aria-expanded="false"
              aria-controls="collapseVarList"
            >
              <div className="sb-nav-link-icon">
                <i className="fa fa-credit-card" />
              </div>
              Variations
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseVarList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link
                  className="nav-link sub_nav_link"
                  to="/variations/deliverycharge"
                >
                  Delivery Charge
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/size"
                >
                  Size
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/brand"
                >
                  Brand
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/variations/list/sub-brand"
                >
                  Sub-Brand
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/color"
                >
                  Color
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/list-variations/flavour"
                >
                  Flavour
                </Link>
              </nav>
            </div>
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseBannerList"
              aria-expanded="false"
              aria-controls="collapseBannerList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-images" />
              </div>
              Banners
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseBannerList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/banners/image">
                  Image
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/cat-banner">
                  Category Banner
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/pop-banner">
                  Pop-Up Banner
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/list-fixedBanner1">
                  Fixed-Banner-1
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/list-fixedBanner2">
                  Fixed-Banner-2
                </Link>
              </nav>
            </div>
            {/* <Link
              className="nav-link collapsed"
              to="/reviews"
              data-toggle="collapse"
              data-target="#collapseReviewList"
              aria-expanded="false"
              aria-controls="collapseReviewList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-comment" />
              </div>
              Reviews
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link> */}
            {/* <Link
              className="nav-link collapsed"
              to="/revenue"
              data-toggle="collapse"
              data-target="#collapseReviewList"
              aria-expanded="false"
              aria-controls="collapseReviewList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-rupee-sign" />
              </div>
              Revenue
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link> */}
            <Link
              className="nav-link collapsed"
              to="#"
              data-toggle="collapse"
              data-target="#collapseRevenueList"
              aria-expanded="false"
              aria-controls="collapseRevenueList"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-images" />
              </div>
              Sales
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </Link>
            <div
              className="collapse"
              id="collapseRevenueList"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/revenue">
                  Revenue
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/top-customers">
                  Top Customers
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/top-products">
                  Top Products
                </Link>
              </nav>
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/create-customer">
                 Create Customer
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SideNavBar;
