import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";
import AllProducts from "./pages/AllProducts";
import AddProduct from "./pages/AddProduct";
import AllCategories from "./pages/Categories/AllCategories";
import AllSubCategories from "./pages/Categories/AllSubCategories";
import AddCategory from "./pages/Categories/AddCategory";
import AddSpecialCategory from "./pages/Categories/AddSpecialCategory";
import AddSubCategory from "./pages/Categories/AddSubCategory";
import AllSpecialCategories from "./pages/Categories/AllSpecialCategories";
import Customers from "./pages/Customers/Customers";
import AllOrders from "./pages/Orders/AllOrders";
import Size from "./pages/Variations/Size";
import ExcelUpload from "./pages/Products/ExcelUpload";
import Brand from "./pages/Variations/Brand";
import Color from "./pages/Variations/Color";
import Flavour from "./pages/Variations/Flavour";
import Supplier from "./pages/Variations/Supplier";
import Weight from "./pages/Variations/Weight";
import UpdateProduct from "./pages/Products/UpdateProduct";
import ListSize from "./pages/Variations/List/ListSize";
import ListBrand from "./pages/Variations/List/ListBrand";
import ListColor from "./pages/Variations/List/ListColor";
import ListFlavour from "./pages/Variations/List/ListFlavour";
import ListWeight from "./pages/Variations/List/ListWeight";
import ListSupplier from "./pages/Variations/List/ListSupplier";
import UpdateOrder from "./pages/Orders/UpdateOrder";
import Sale from "./pages/Sale";
import OrderDetail from "./pages/Orders/OrderDetail";
import ImageBanner from "./pages/Banners/ImageBanner";
import ListImgBanner from "./pages/Banners/ListImgBanner";
// import AllVideos from "./pages/Banners/AllVideos";
// import AddVideoBanner from "./pages/Banners/AddVideoBanner";
import CreateDelivery from "./pages/Orders/CreateDelivery";
import DeliveryByEmployee from "./pages/Orders/DeliveryByEmployee";
import Dashboard from "./pages/Dashboard/Dashboard";
import useAuth from "./auth/useAuth";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AllReview from "./pages/AllReview";
import ProductReview from "./pages/ProductReview";
import Revenue from "./pages/Dashboard/Revenue";
import EditCategory from "./pages/Categories/EditCategory";
import EditSubCategory from "./pages/Categories/EditSubCategory";
import EditSpecialCategory from "./pages/Categories/EditSpecialCategory";
import SearchProducts from "./pages/SearchProducts";
import OrderFilter from "./pages/Orders/OrderFilter";
import FailedOnline from "./pages/Orders/FailedOnline";
// import AllTextBanners from "./pages/Banners/AllTextBanners";
// import AddTextBanner from "./pages/Banners/AddTextBanner";
// import UpdateTextBanner from "./pages/Banners/UpdateTextBanner";
import SendNotification from "./pages/Customers/SendNotification";
import Dealers from "./pages/Customers/Dealers";
import DeliveryCharge from "./pages/Variations/DeliveryCharge";
import Pincode from "./pages/Pincode/Pincode";
import AddPincode from "./pages/Pincode/AddPincode";
import Coupen from "./pages/Coupens/Coupen";
import AddCoupen from "./pages/Coupens/AddCoupen";
import DealerPrice from "./pages/DealerPrice";
import Footer from "./components/Footer";
import DeliverySlot from "./pages/DeliverySlot/DeliverySlot";
import AddDeliverySlot from "./pages/DeliverySlot/AddDeliverySlot";
import AddTheme from "./pages/ThemeColor/AddTheme";
import AllTheme from "./pages/ThemeColor/AllTheme";
import AddHeading from "./pages/ThemeColor/AddHeading";
import AllHeading from "./pages/ThemeColor/AllHeading";
import UpdateHeading from "./pages/ThemeColor/UpdateHeading";
import AddCatBanner from "./pages/Banners/AddCatBanner";
import ListCatBanner from "./pages/Banners/ListCatBanner";
import OrderAgain from "./pages/Products/OrderAgain";
import AddOrderAgain from "./pages/Products/AddOrderAgain";
import Reward from "./pages/Customers/Reward";
import SubBrand from "./pages/Variations/SubBrand";
import ListSubBrand from "./pages/Variations/List/ListSubBrand";
import TopCustomers from "./pages/Dashboard/TopCustomers";
import TopProducts from "./pages/Dashboard/TopProducts";

import CreateCustomer from "./pages/Customers/createCustomer"
import AddPopBanner from "./pages/Banners/AddPopBanner";
import AllPopBanner from "./pages/Banners/AllPopBanner";
import AllFixedBanner1 from "./pages/Banners/AllFixedBanner1";
import AllFixedBanner2 from "./pages/Banners/AllFixedBanner2";
import AddFixedBanner1 from "./pages/Banners/AddFixedBanner1";
import AddFixedBanner2 from "./pages/Banners/AddFixedBanner2";
import UpdateDeliverySlot from "./pages/DeliverySlot/UpdateDeliverySlot";

function HeaderComponent() {
  const { user } = useAuth();
  if (!user) {
    return <Login />;
  }

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/product/list" element={<AllProducts />}></Route>
          <Route path="/product/search" element={<SearchProducts />}></Route>
          <Route path="/category/edit" element={<EditCategory />}></Route>
          <Route
            path="/category/editspecialcategory"
            element={<EditSpecialCategory />}
          ></Route>
          <Route
            path="/category/editsubcategory"
            element={<EditSubCategory />}
          ></Route>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/reviews" element={<AllReview />}></Route>
          <Route path="/revenue" element={<Revenue />}></Route>
          <Route path="/productreview" element={<ProductReview />}></Route>

          <Route path="/product/excel" element={<ExcelUpload />}></Route>
          <Route path="/product/create" element={<AddProduct />}></Route>
          <Route path="/product/update" element={<UpdateProduct />}></Route>

          <Route path="/category/create-category" element={<AddCategory />} />
          <Route path="/category/sub-create" element={<AddSubCategory />} />
          <Route path="/category/list" element={<AllCategories />} />
          <Route path="/category/sublist" element={<AllSubCategories />} />
          <Route
            path="/category/speciallist"
            element={<AllSpecialCategories />}
          />
          <Route
            path="/category/special-category"
            element={<AddSpecialCategory />}
          />
          {/* <Route path="/banners/text" element={<AllTextBanners />} /> */}
          {/* <Route path="/banners/addtextbanner" element={<AddTextBanner />} /> */}
          {/* <Route
            path="/banners/updatetextbanner"
            element={<UpdateTextBanner />}
          /> */}
          <Route path="/customer/list" element={<Customers />} />
          <Route path="/customer/dealerlist" element={<Dealers />} />
          <Route path="/customer/notification" element={<SendNotification />} />
          <Route path="/orders/list" element={<AllOrders />} />
          <Route path="/orders/order-filter" element={<OrderFilter />} />
          <Route path="/orders/failed-online" element={<FailedOnline />} />
          <Route path="/orders/update" element={<UpdateOrder />} />
          <Route path="/variations/size" element={<Size />} />
          <Route path="/list-variations/size" element={<ListSize />} />
          <Route path="/variations/brand" element={<Brand />} />
          <Route path="/variations/sub-brand" element={<SubBrand />} />
          <Route path="/variations/list/sub-brand" element={<ListSubBrand />} />
          <Route
            path="/variations/deliverycharge"
            element={<DeliveryCharge />}
          />
          <Route path="/list-variations/brand" element={<ListBrand />} />
          <Route path="/variations/color" element={<Color />} />
          <Route path="/list-variations/color" element={<ListColor />} />
          <Route path="/variations/supplier" element={<Supplier />} />
          <Route path="/list-variations/supplier" element={<ListSupplier />} />
          <Route path="/variations/weight" element={<Weight />} />
          <Route path="/list-variations/weight" element={<ListWeight />} />
          <Route path="/variations/flavour" element={<Flavour />} />
          <Route path="/list-variations/flavour" element={<ListFlavour />} />
          <Route path="/category/sale" element={<Sale />} />
          <Route path="/orders/detail" element={<OrderDetail />} />
          {/* <Route path="/banners/videos" element={<AllVideos />} />
          <Route path="/banners/addVideoBanner" element={<AddVideoBanner />} /> */}
          <Route path="/banners/image" element={<ListImgBanner />} />
          <Route path="/banners/addImgBanner" element={<ImageBanner />} />
          <Route path="/orders/delivery" element={<CreateDelivery />} />
          <Route path="/product/dealerPrice" element={<DealerPrice />} />
          <Route path="/add-cat-banner" element={<AddCatBanner />} />
          <Route path="/add-pop-banner" element={<AddPopBanner />} />
          <Route path="/list-fixedBanner1" element={<AllFixedBanner1 />} />
          <Route path="/list-fixedBanner2" element={<AllFixedBanner2 />} />
          <Route path="/add-fixedBanner1" element={<AddFixedBanner1 />} />
          <Route path="/add-fixedBanner2" element={<AddFixedBanner2 />} />
          <Route path="/order-again" element={<OrderAgain />} />
          <Route path="/cat-banner" element={<ListCatBanner />} />
          <Route path="/pop-banner" element={<AllPopBanner />} />

          <Route path="/list-pincodes/" element={<Pincode />} />
          <Route path="/add-pincode/" element={<AddPincode />} />
          <Route path="/list-deliveryslot/" element={<DeliverySlot />} />
          <Route path="/update-deliveryslot/" element={<UpdateDeliverySlot />} />
          <Route path="/add-deliveryslot/" element={<AddDeliverySlot />} />
          <Route path="/add-heading" element={<AddHeading />} />
          <Route path="/all-heading/" element={<AllHeading />} />
          <Route path="/update-heading" element={<UpdateHeading />} />
          <Route path="/list-coupens/" element={<Coupen />} />
          <Route path="/add-coupen/" element={<AddCoupen />} />
          <Route path="/add-theme/" element={<AddTheme />} />
          <Route path="/all-theme/" element={<AllTheme />} />
          <Route path="/reward/" element={<Reward />} />
          <Route path="/top-customers/" element={<TopCustomers />} />
          <Route path="/top-products/" element={<TopProducts />} />
          <Route path="/add-orderagain/" element={<AddOrderAgain />} />
          <Route path="/create-customer" element={<CreateCustomer />} />
          <Route
            path="/orders/deliveryByEmployee"
            element={<DeliveryByEmployee />}
          />
        </Routes>
      </div>
    </>
  );
}

export default HeaderComponent;
